import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../styles/components/button.emotion";
import { HomeNewsletter } from "../../styles/components/home/home-newsletter.emotion";
import { Error, FormControl, Label } from "../../styles/utils/form.emotion";
import { Container, Wrapper } from "../../styles/utils/global.emotion";
import { Description, SubTitle } from "../../styles/utils/typograth.emotion";
import * as React from "react";
import { useAlert } from "../../context/alert.context";
import { Message } from "../../models/message.model";
import api from "../../service/api";

interface Form {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export default function HomeNewsletterComponent() {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { presentAlert } = useAlert();

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = async (fields) => {
    setSubmitting(true);

    const data = {
      ...new Message(),
      subject: "Newsletter",
      name: fields?.name,
      email: fields?.email,
      phone: fields?.phone,
      message: fields?.message,
    } as Message;

    const response = await api.post("message", data);

    if (response?.status === 200) {
      presentAlert({
        title: "Sucesso!",
        message:
          "Logo você recebera ofertas exclusivas da Bomarzo em seu e-mail",
        buttonText: "Certo!",
      });

      reset({
        name: "",
        email: "",
      });
    }

    setSubmitting(false);
  };

  return (
    <HomeNewsletter>
      <Container size="xg">
        <Wrapper
          justifyContent={"space-between"}
          mobileOptions={{ flexDirection: "column" }}
        >
          <div className="col">
            <SubTitle>Newsletter</SubTitle>
            <Description>
              Assine nossa newsletter e receba ofertas exclusivas da{" "}
              <b>Bomarzo</b>
            </Description>
          </div>
          <div className="content">
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <Wrapper
              className="content-form"
              alignItems={"center"}
              justifyContent={"space-between"}
              mobileOptions={{ flexWrap: "wrap", gap: "3vw" }}
            >
              <FormControl size={40} themeColor="outline">
                <Label>Nome</Label>
                <input
                  id="form-newsletter-name"
                  {...register("name", {
                    required: "Este campo é requirido",
                  })}
                  placeholder="Digite seu nome"
                />
                {errors?.name ? (
                  <Error>
                    <i className="fa-solid fa-info-circle"></i>
                    <span>{errors?.name?.message}</span>
                  </Error>
                ) : null}
              </FormControl>
              <FormControl size={40} themeColor={"outline"}>
                <Label>Email</Label>
                <input
                  id="form-newsletter-email"
                  {...register("email", {
                    required: "Este campo é requirido",
                  })}
                  type="email"
                  placeholder="Digite seu e-mail"
                />
                {errors?.email ? (
                  <Error>
                    <i className="fa-solid fa-info-circle"></i>
                    <span>{errors?.email?.message}</span>
                  </Error>
                ) : null}
              </FormControl>
              <Button
                buttonStyle="default"
                as="button"
                type="submit"
                disabled={isSubmitting}
                onClick={() => handleSubmit(onSubmit)()}
              >
                {isSubmitting ? (
                  <i className="fas fa-spin fa-spinner"></i>
                ) : (
                  <span>Enviar</span>
                )}
              </Button>
            </Wrapper>
            {/* </form> */}
          </div>
        </Wrapper>
      </Container>
    </HomeNewsletter>
  );
}
