import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const HomeProducts = styled.section`
  position: relative;
  margin-bottom: max(3.125rem, 7.4vw);

  .home-products {
    &__title {
      margin-bottom: 3.125rem;

      h2 {
        ${() => useMediaQuery("sm", "min")} {
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            right: -1rem;
            width: 20vw;
            height: 2px;
            background-color: ${(props) => props.theme.colors.green};
            transform: translate(100%, -50%);
          }
        }
      }
    }
  }

  .arrow-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      display: block;
      margin: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.text};

      & + button {
        margin-left: 0.5rem;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.text};
        color: ${(props) => props.theme.colors.white};
      }

      &:disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }

  ${() => useMediaQuery("sm", "min")} {
    h2 {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -1rem;
        width: 20vw;
        height: 2px;
        background-color: ${(props) => props.theme.colors.green};
        transform: translate(100%, -50%);
      }
    }
  }
`;
