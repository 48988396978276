import Image from "next/image";
import { Feedback } from "../../models/feedback.model";
import { TestimonialItem } from "../../styles/components/testimonial/testimonial-item.emotion";
import { Container } from "../../styles/utils/global.emotion";
import { Description } from "../../styles/utils/typograth.emotion";

interface Props {
  item: Feedback;
}

export default function TestimonialItemComponent({ item }: Props) {
  return (
    <TestimonialItem>
      {item?.image && (
        <Image
          src={item?.image}
          alt={item?.name}
          width={128}
          height={128}
          className="image"
        />
      )}
      {item?.name && <h3>{item?.name}</h3>}
      {item?.text && <Description>{item?.text}</Description>}
    </TestimonialItem>
  );
}
