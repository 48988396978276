import { useKeenSlider } from "keen-slider/react";
import { HomeTestimonials } from "../../styles/components/home/home-testimonials.emotion";
import { Container, Wrapper } from "../../styles/utils/global.emotion";
import TestimonialItemComponent from "../testimonial/testimonial-item.component";
import * as React from "react";
import { SubTitle } from "../../styles/utils/typograth.emotion";
import { Feedback } from "../../models/feedback.model";

interface Props {
  feedbacks: Feedback[];
}

export default function HomeTestimonialsComponent({ feedbacks }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 3,
      spacing: 0,
    },
    breakpoints: {
      "(max-width: 992px)": {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
      "(max-width: 576px)": {
        slides: {
          perView: 1,
          spacing: 16,
        },
      },
    },
    slideChanged: (ev) => {
      setCurrentIndex(ev?.track?.details.abs);
    },
  });
  return (
    <HomeTestimonials>
      <Container size="xg">
        <Wrapper
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          className="home-products__title"
        >
          <SubTitle>Depoimentos</SubTitle>
        </Wrapper>
        <div className="keen-slider" ref={ref}>
          {feedbacks?.map((item, index) => (
            <div className="keen-slider__slide" key={`feedback-${index}`}>
              <TestimonialItemComponent item={item} />
            </div>
          ))}
        </div>
        <div className="arrow-container">
          <button
            onClick={() => slider.current?.prev()}
            disabled={currentIndex === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <button
            onClick={() => slider.current?.next()}
            disabled={currentIndex === slider?.current?.track?.details?.maxIdx}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </Container>
    </HomeTestimonials>
  );
}
