import { Product } from "../models/product.model";
import { FetchProps } from "../shared/helper";
import api from "./api";

const END_POINT = "/product";

export interface ProductGetProps {
  filter?: string;
  page?: number;
  count?: number;
  orderColumn?: string;
  orderDirection?: string;
  situation?: string;
  idSubcategory?: string;
  slugCategory?: string;
  slugSubcategory?: string;
}

const productService = {
  get: async (params?: ProductGetProps) => {
    return await api.get<FetchProps<Product>>(END_POINT, {
      params,
    });
  },
  getBySlug: async (slug?: string) => {
    return await api.get<Product>(`${END_POINT}/slug/${slug}`);
  },
  getRelated: async (idProduct?: string) => {
    return await api.get<Product[]>(`${END_POINT}/related`, {
      params: {
        id: idProduct,
      },
    });
  },
  calculateTax: (
    installments: number,
    amount: number,
    freightValue: number = 0
  ) => {
    let valueTax = 0;




    if (installments > 4) {
      if (installments == 5) {
        //1,5% de juros
        valueTax = amount * 0.015;
      } else if (installments == 6) {
        //3% de juros
        valueTax = amount * 0.03;
      } else if (installments == 7) {
        //4,5% de juros
        valueTax = amount * 0.045;
      } else if (installments == 8) {
        //6% de juros
        valueTax = amount * 0.06;
      } else if (installments == 9) {
        //7,5% de juros.
        valueTax = amount * 0.075;
      } else if (installments == 10) {
        //9% de juros
        valueTax = amount * 0.09;
      }
    }

    const total = amount + valueTax + freightValue;
    const valueParcel = total / installments;

    return { total, valueParcel };
  },
};

export default productService;
