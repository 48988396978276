import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const HomeNewsletter = styled.section`
  position: relative;
  padding-bottom: clamp(6rem, 7.4vw, 7rem);
  .col {
    width: 30%;
    margin-right: 2.5vw;
  }
  h2 {
    margin-bottom: 1.125rem;
  }
  .content {
    display: flex;
    width: 70%;
    &-form {
      width: 100%;
    }
  }
  button {
    min-width: auto;
    margin: 0;
    margin-top: 0.625rem;
    height: 3.813rem;
  }
  ${() => useMediaQuery("sm", "max")} {
    .col {
      width: 100%;
      margin-bottom: 2.125rem;
    }
    .content {
      width: 100%;
    }
  }
`;
