import type { GetStaticProps, InferGetStaticPropsType, NextPage } from "next";
import FooterContactComponent from "../components/footer-contact.component";
import FooterComponent from "../components/footer.component";
import HeaderComponent from "../components/header/header.component";
import HomeAboutComponent from "../components/home/home-about.component";
import HomeBannerComponent from "../components/home/home-banner.component";
import HomeHighlightsComponent from "../components/home/home-highlights.component";
import HomeNewsletterComponent from "../components/home/home-newsletter.component";
import HomeProductsComponent from "../components/home/home-products.component";
import HomeTestimonialsComponent from "../components/home/home-testimonials.component";
import { Category } from "../models/category.model";
import bannerService from "../service/banners.service";
import categoryService from "../service/category.service";
import feedbackService from "../service/feedback.service";
import generalRegisterService from "../service/general-register.service";
import highlightsService from "../service/highlights.service";
import { Home } from "../styles/components/home/home.emotion";
import DialogCatalogComponent from "../components/dialog/dialog-catalog.component";

export const getStaticProps: GetStaticProps = async () => {
  const banners = await bannerService
    .get({ situation: "A" })
    .then((res) => res?.data?.data);

  const highlights = await highlightsService
    .get()
    .then((res) => res?.data?.data);

  const general = await generalRegisterService.get().then((res) => res?.data);
  const categories = await categoryService
    .get({ situation: "A" })
    .then((res) => res?.data?.data);
  const feedbacks = await feedbackService.get().then((res) => res?.data?.data);

  return {
    props: {
      banners,
      highlights,
      general,
      categories,
      feedbacks,
    },
    revalidate: 60,
  };
};

const HomePage: NextPage = ({
  banners,
  highlights,
  general,
  categories,
  feedbacks,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <>
      <HeaderComponent />
      <Home>
        {banners?.length ? <HomeBannerComponent banners={banners} /> : null}

        {highlights?.length ? (
          <HomeHighlightsComponent highlights={highlights} />
        ) : null}

        <HomeAboutComponent general={general} />

        {categories?.map((item: Category, index: number) => (
          <HomeProductsComponent category={item} key={`category-${index}`} />
        ))}

        {feedbacks?.length ? (
          <HomeTestimonialsComponent feedbacks={feedbacks} />
        ) : null}

        <HomeNewsletterComponent />
      </Home>
      <FooterContactComponent />
      <FooterComponent />
    </>
  );
};

export default HomePage;
