import classNames from "classnames";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import * as React from "react";
import { Highlights } from "../../models/highlights.model";
import {
  HomeHighlights,
  HomeHighlightsItem,
} from "../../styles/components/home/home-highlights.emotion";
import { Container, Wrapper } from "../../styles/utils/global.emotion";

interface Props {
  highlights: Highlights[];
}

export default function HomeHighlightsComponent({ highlights }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [ref, slider] = useKeenSlider<HTMLDivElement>(
    {
      slideChanged: (ev) => {
        setCurrentIndex(ev.track.details.abs);
      },
      slides: {
        perView: 4,
        spacing: 20,
      },
      breakpoints: {
        "(max-width: 768px)": {
          slides: {
            perView: 2,
            spacing: 20,
          },
          loop: true, 
        },
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3500);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <HomeHighlights>
      <Container size="lg">
        <Wrapper
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mobileOptions={{ flexDirection: "column", gap: "2rem" }}
        >
          <div className="keen-slider" ref={ref}>
            {highlights?.map((item, index) => (
              <div className="keen-slider__slide" key={`highlights-${index}`}>
                <HomeHighlightsItem>
                  {item?.image && (
                    <Image
                      src={item?.image}
                      width={64}
                      height={64}
                      alt={item?.name}
                    />
                  )}
                  <span>{item?.name}</span>
                </HomeHighlightsItem>
              </div>
            ))}
          </div>
        </Wrapper>
      </Container>
    </HomeHighlights>
  );
}
