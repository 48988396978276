import styled from "@emotion/styled";

export const TestimonialItem = styled.figure`
  position: relative;
  padding: 2rem;
  margin: 1.125rem;
  box-shadow: 0 0 10px #00000020;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .image {
    position: relative;
    margin-bottom: 1rem;
    object-fit: cover;
    min-width: 6.5rem;
    min-height: 6.5rem;
    max-width: 6.5rem;
    max-height: 6.5rem;
    border-radius: 9999px;
    margin-bottom: 2.125rem;

    img {
      object-fit: cover;
      object-position: top;
    }
  }

  h3 {
    font-size: 1.25em;
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    margin-bottom: 1.125rem;
  }

  p {
    text-align: center;
  }
`;
