import { HomeBanner } from "../../styles/components/home/home-banner.emotion";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import { useState } from "react";
import classNames from "classnames";
import { Banner } from "../../models/banner.model";

interface Props {
  banners: Banner[];
}

export default function HomeBannerComponent({ banners }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ref, slider] = useKeenSlider<HTMLDivElement>(
    {
      slideChanged: (ev) => {
        setCurrentIndex(ev.track.details.rel);
      },
      loop: true,
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3500);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <HomeBanner>
      <div ref={ref} className="keen-slider">
        {banners?.map((item, index) => (
          <div className="keen-slider__slide" key={`banner-item-${index}`}>
            <a>
              <Image src={item?.image} alt={item?.title} fill sizes="100vw" />
            </a>
          </div>
        ))}
      </div>
      {banners?.length > 1 && (
        <div className="dots">
          {banners?.map((item, idx) => (
            <button
              className={classNames("dots__item", {
                active: currentIndex === idx,
              })}
              onClick={() => slider?.current?.moveToIdx(idx)}
              key={`dot-item-${idx}`}
            ></button>
          ))}
        </div>
      )}
    </HomeBanner>
  );
}
