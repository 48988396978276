import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const HomeBanner = styled.section`
  position: relative;

  .keen-slider {
    .keen-slider__slide {
      padding-bottom: 26vw;
      background-color: ${(props) => props.theme.colors.green};

      a {
        cursor: pointer;
        img {
          object-fit: cover;
        }
      }
    }
  }

  .dots {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;

    &__item {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid ${(props) => props.theme.colors.white};
      margin: 0 4px;

      &.active {
        background-color: ${(props) => props.theme.colors.white};
      }
    }
  }

  ${() => useMediaQuery("sm")} {
    margin-top: 2rem;
  }
`;
