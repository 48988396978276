import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const HomeHighlights = styled.section`
  padding: max(5.2vw, 2rem) 0;
  .dots {
    position: relative;
    z-index: 3;
    min-height: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 3.125rem;

    &__item {
      width: 8px;
      height: 8px;
      border-radius: 9999px;
      background-color: ${(props) => props.theme.colors.gray};
      transition: var(--transition-base);
      &:hover,
      &.active {
        background-color: ${(props) => props.theme.colors.green};
        height: 8px;
      }
    }
  }
`;

export const HomeHighlightsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex: 1;
  position: relative;
  width: 100%;

  &:first-of-type {
    &::before {
      display: none;
    }
  }

  img {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }

  span {
    line-height: 1.5;
    max-width: 100%;
    display: block;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-1.5vw, -50%);
    width: 1px;
    height: 4rem;
    background-color: ${(prosp) => prosp.theme.colors.green};
  }

  /* ${() => useMediaQuery("sm")} {
    max-width: calc(25% - 2vw);
  } */
  ${() => useMediaQuery("sm", "max")} {
    justify-content: center;
    span {
      flex-grow: unset;
      max-width: unset;
    }
    &:before {
      content: unset;
    }
  }
`;
