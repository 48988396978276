import styled from "@emotion/styled";
import { useMediaQuery } from "../../utils/responsive.emotion";

export const HomeAbout = styled.section`
  background-color: ${(props) => props.theme.colors.snow};
  position: relative;
  padding-left: 55%;
  padding-right: 10%;
  margin-bottom: max(3.125rem, 7.4vw);

  .content {
    padding: 5.2vw 0;

    h2 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 3.125rem;
    }
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;

    img {
      object-fit: cover;
    }
  }
  ${() => useMediaQuery("sm", "max")} {
    padding: 2rem 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(247 247 247 / 90%);
    margin-bottom: 4rem;
    .image {
      width: 100%;
      z-index: -1;
    }
  }
`;
