import Image from "next/image";
import Link from "next/link";
import * as React from "react";
import { useGlobal } from "../../context/global.context";
import { GeneralRegister } from "../../models/general-register.model";
import { Button } from "../../styles/components/button.emotion";
import { HomeAbout } from "../../styles/components/home/home-about.emotion";
import { Container, Wrapper } from "../../styles/utils/global.emotion";
import { Description, Title } from "../../styles/utils/typograth.emotion";

interface Props {
  general: GeneralRegister;
}

export default function HomeAboutComponent({ general }: Props) {
  return (
    <HomeAbout>
      <div className="image">
        <Image
          src={general?.productHomeImage}
          alt="Conheça nossos produtos"
          fill
          sizes="100vw"
          priority
        />
      </div>
      <div className="content">
        <Title>{general?.productHomeTitle || "Conheça nossos produtos"}</Title>

        <Description
          dangerouslySetInnerHTML={{ __html: general?.productHomeDescription }}
          as="div"
        />
        <Link href="/produtos">
          <Button buttonStyle="outlined" as={"button"}>
            <span>Ver produtos</span>
          </Button>
        </Link>
      </div>
    </HomeAbout>
  );
}
