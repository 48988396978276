import * as React from "react";
import { useKeenSlider } from "keen-slider/react";
import { Button } from "../../styles/components/button.emotion";
import { HomeProducts } from "../../styles/components/home/home-products.emotion";
import { Container, Wrapper } from "../../styles/utils/global.emotion";
import { SubTitle } from "../../styles/utils/typograth.emotion";
import ProductItemComponent from "../product/product-item.component";
import { Product } from "../../models/product.model";
import Link from "next/link";
import { Category } from "../../models/category.model";
import { useQuery } from "@tanstack/react-query";
import productService from "../../service/product.service";

interface Props {
  category: Category;
}

export default function HomeProductsComponent({ category }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 4,
      spacing: 16,
    },
    breakpoints: {
      "(max-width: 992px)": {
        slides: {
          perView: 3,
          spacing: 16,
        },
      },
      "(max-width: 576px)": {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
    },
    slideChanged: (ev) => {
      setCurrentIndex(ev?.track?.details.abs);
    },
  });

  const fetchProducts = async () => {
    const response = await productService.get({
      page: 1,
      count: 6,
      slugCategory: category?.slug,
      situation: "A",
    });
    return response?.data?.data;
  };

  const query = useQuery(
    [`home-products-${category?.slug}`, category],
    fetchProducts
  );

  const products = query?.data;

  if (!products?.length) return null;

  return (
    <HomeProducts>
      <Container size="xg">
        <Wrapper
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          className="home-products__title"
        >
          <SubTitle>{category?.name}</SubTitle>
          <div className="right">
            <Link href="/produtos/[...slug]" as={`/produtos/${category?.slug}`}>
              <Button buttonStyle="default">
                <span>Ver todos</span>
              </Button>
            </Link>
          </div>
        </Wrapper>
        <div className="keen-slider" ref={ref}>
          {products?.map((item: Product, index: number) => (
            <div className="keen-slider__slide" key={`product-${index}`}>
              <ProductItemComponent item={item} />
            </div>
          ))}
        </div>
        <div className="arrow-container">
          <button
            onClick={() => slider.current?.prev()}
            disabled={currentIndex === 0}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <button
            onClick={() => slider.current?.next()}
            disabled={currentIndex === slider?.current?.track?.details?.maxIdx}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </Container>
    </HomeProducts>
  );
}
