import styled from "@emotion/styled";

export const HomeTestimonials = styled.section`
  position: relative;
  padding-bottom: 7.4vw;
  .keen-slider__slide {
    display: flex;
  }
  h2 {
    margin-bottom: 3.125rem;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: -2rem;
      width: 20vw;
      height: 2px;
      background-color: ${(props) => props.theme.colors.green};
      transform: translate(100%, -50%);
    }
  }
  .arrow-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      display: block;
      margin: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.text};

      & + button {
        margin-left: 0.5rem;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.text};
        color: ${(props) => props.theme.colors.white};
      }

      &:disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
`;
